<template>
  <div class="setting-image" v-if="!isProcessing">
    <p class="setting-image__description">
      入会特典ページの上部に表示されるイメージ画像を変更することができます。
    </p>
    <!-- トップ画像 -->
    <label class="setting-image__upload">
      <v-img class="setting-image__upload__img" v-if="img" :src="img" />
      <div class="setting-image__upload__cover">
        <v-icon class="setting-image__upload__cover__icon">camera_alt</v-icon>
        <span class="setting-image__upload__cover__icon__required">*</span>
      </div>
      <input class="setting-image__upload__input"
             @change="selectPicture" type="file" accept=".png, .jpg, .jpeg, .gif" />
    </label>
    <v-btn class="setting-image__btn" :disabled="!isFilled" depressed @click="editTopImage()">変更する</v-btn>
  </div>
</template>

<script>
import image from '@/assets/lib/image'

export default {
  name: 'setting_image',
  mixins: [image],
  data () {
    return {
      // トップ画像
      img: '',
      // Blobファイル
      blob: null,
      // 画像の格納先のパス名
      path: '/settings/'
    }
  },
  async mounted () {
    // トップ画像を取得
    if (!this.settingImage) await this.$store.dispatch('settingImages/getSettingImage', 'visual')

    this.img = this.settingImage ? this.settingImage.icon : null

    this.$store.commit('setProcessing', false)
  },
  computed: {
    /**
     * @return {Object} top画像情報
     */
    settingImage () {
      return this.$store.getters['settingImages/settingImage']('visual')
    },
    /**
     * @return {Boolean} 処理中かどうか
     */
    isProcessing () {
      return this.$store.getters.isProcessing
    },
    /**
     * @return {Boolean} 画像が選択されているかどうか
     */
    isFilled () {
      return !!this.img && !!this.blob
    }
  },
  methods: {
    /**
     * トップ画像の更新
     */
    async editTopImage () {
      this.$store.commit('setSubmitting', true)

      const icon = await this.$store.dispatch('cloudstorage/uploadImage', { blob: this.blob, path: this.path })
      const params = {
        icon: icon,
        type: 'visual',
        updatedAt: new Date()
      }

      // setting_imagesが存在すれば更新、なければ新規追加を行う
      if (this.settingImage) {
        // 削除用のURLを変数に格納
        const url = this.settingImage.icon
        await this.$store.dispatch('settingImages/updateSettingImage', { siid: this.settingImage.siid, params: params })

        // 先に画像を削除するとユーザー画面でエラーが出る可能性があるのでsetting_imagesの更新、追加後に削除処理を行う
        // 画像の削除は同期処理の必要はないので非同期で行う
        this.$store.dispatch('cloudstorage/deleteImage', url)
      } else {
        params.createdAt = new Date()
        await this.$store.dispatch('settingImages/addSettingImage', params)
      }

      this.$store.commit('setTelop', { show: true, msg: 'トップ画像を更新しました。', type: 'success' })
      this.$router.push({ name: 'home' })
    },
    /**
     * 画像の選択
     * @param {Object} event 発火したイベント
     */
    async selectPicture (event) {
      event.preventDefault()

      // 保存先URLパスとファイルの格納
      const file = event.target.files[0]

      const results = await this.doImgProcess(file, this.path)
      if (!results) return

      this.img = results.url
      this.blob = results.blob
      this.path = results.path
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/size.scss';
@import '@/assets/sass/color.scss';
@import '@/assets/sass/fontfamily.scss';

.setting-image {
  width: 100vw;
  max-width: $max_width;
  min-height: calc(100vh - #{$header_height});
  padding: $padding_height $padding_width;
  margin: $header_height auto 0;
  &__description {
    margin: 0;
    font-size: 1.2rem;
  }
  &__upload {
    position: relative;
    display: block;
    width: 100vw;
    max-width: $max_width;
    height: 48.6vw;
    max-height: calc(#{$max_width} * 0.486);
    margin: 20px -#{$padding_width} 0;
    background-color: $gray_lighten_color;
    border-color: transparent;
    &__img {
      width: 100%;
      height: 100%;
    }
    &__cover {
      position: absolute;
      top: 50%;
      left: 50%;
      display: flex;
      align-items: flex-start;
      transform: translate(-50%, -50%);
      &__icon {
        font-family: $material-outlined;
        object-fit: cover;
        &.v-icon {
          font-size: 4.5rem;
          color: $gray_color;
        }
        &__required {
          font-size: 1.6rem;
          line-height: 2rem;
          color: $red_color;
        }
      }
    }
    &__input {
      display: none;
    }
  }
  &__btn {
    display: block;
    margin: 30px auto 0 auto;
    font-size: 1.2rem;
    color: $white_color;
    border-radius: 10px;
    &.v-btn:not(.v-btn--round).v-size--default {
      min-width: auto;
      height: auto;
      padding: 10px 15px;
    }
    &.theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
      background-color: $orange_color;
    }
    &.theme--light.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
      color: $white_color !important;
      background-color: $orange_lighten_color !important;
    }
  }
}
</style>
